import React, { useState, useEffect } from 'react';
import mochTopLogo from '../assets/img/mocheTopLogo.svg';

//confeti animation
import Confetti from 'react-dom-confetti';
import Countdown from './Countdown';

const PreStart = () => {
    const [indexText, setindexText] = useState(1);
    const [explosion, setexplosion] = useState(false);
    const [explosion2, setexplosion2] = useState(false);
    const [goCount, setgoCount] = useState(false);

    const config = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 140,
        dragFriction: 0.01,
        duration: 4000,
        stagger: 3,
        width: '50px',
        height: '50px',
        perspective: '500px',
        colors: ['#ff5959ff', '#ccb3ffff', '#ffdf80ff', '#7cd9c8ff'],
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            //window.location.href = '/count';
            setexplosion(false)
            setgoCount(true)
        }, 6000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setexplosion(true);
            setexplosion2(true);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setindexText((indexText) =>
                indexText === 3 ? indexText - 3 : indexText + 1
            );
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    if (explosion) {
        return (
            <div className="PreStart">
                <div className="flex-box">
                    <div className="explosion">
                        <Confetti active={explosion2} config={config} />
                    </div>
                </div>
            </div>
        );
        }else if(goCount){
            return(
                <Countdown />
            )
        } else {
        return (
            <div className="PreStart">
                <div>
                    <img
                        src={mochTopLogo}
                        className="mochTopLogo"
                        alt="main top logo"
                    />
                </div>
                <div className="flex-box">
                    <div>
                        {indexText === 1 ? (
                            <p className="item1">Parece Fácil?</p>
                        ) : indexText === 2 ? (
                            <p className="item2">Quando Acabares, Falamos.</p>
                        ) : (
                            <p className="item3">
                                <span className="mind-blown">
                                    Mind: Blown!{' '}
                                </span>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        );
    }
};

export default PreStart;
