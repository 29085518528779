import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import data from '../assets/data/dataCounter';
import rightBar from '../assets/img/rightBarCount.svg';

function Result(props) {
    const [index] = useState(0);

    const [countData] = useState(data);

    const history = useHistory();

    useEffect(() => {
        const interval = setInterval(() => {
            history.push('/screen');
        }, 6000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="Result">
            <div>
                <img
                    src={countData[2].barraMoche}
                    className="barraMoche"
                    alt="barra moche"
                />
            </div>
            <div>
                <img src={rightBar} alt="left bar" className="rightBarResult" />
            </div>
            <div
                className="numbersResult"
                style={{ color: countData[index].color }}
            >
                {props.gamePoints ? props.gamePoints : 0}
                <p>
                    {props.gamePoints === 1 ? (
                        <span>ponto</span>
                    ) : (
                        <span>pontos</span>
                    )}
                </p>
                <p>
                    {props.gamePoints < 5 || !props.gamePoints ? (
                        <span>Color Blocked!</span>
                    ) : props.gamePoints < 9 ? (
                        <span>
                            Quase que te
                            <br /> estampavas!
                        </span>
                    ) : props.gamePoints < 11 ? (
                        <span>Boa paleta!</span>
                    ) : (
                        <span>
                            Sabes isso
                            <br /> de cor!
                        </span>
                    )}
                </p>
            </div>
            <div className="crossResult">
                <img
                    src={countData[1].cross}
                    alt="left bar"
                    className="rightBarResult"
                />
            </div>
        </div>
    );
}

export default Result;
//{props.gamePoints}
