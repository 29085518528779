import React, { useState, useEffect } from 'react';
import { socket } from '../service/socket';
import { useParams } from 'react-router-dom';
import RemoteLogoSVG from '../assets/img/remoteLogo.svg';

const buttonsStyle = {
    height: 100,
    width: 100,
};

function RemoteControl() {
    let { userId } = useParams();

    const [isCurrentUser, setIsCurrentUser] = useState(false);
    const [gameState, setGameState] = useState({ status: 'idle', time: 0 });
    const [containerStyle, setContainerStyle] = useState({
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        width: visualViewport.width,
        height: visualViewport.height,
        backgroundColor: '#12171A',
        userSelect: 'none',
    });

    useEffect(() => {
        socket.on('connect', () => {
            console.log('connected');
            socket.emit('addUser', userId);
        });

        socket.on('currentUser', (currentUserId) => {
            setIsCurrentUser(currentUserId === userId);
            console.log(currentUserId, userId, isCurrentUser);
        });

        socket.on('disconnect', (reason) => {
            console.log('disconnected', reason);
            socket.emit('removeUser', userId);
        });

        socket.on('gameState', (state) => {
            console.log('gameState', state);
            if (state.status === 'finished') {
                socket.emit('removeUser', userId);
                setIsCurrentUser(false);
            }
            setGameState(state);
        });

        return () => {
            socket.off();
        };
    }, [userId]);

    const sendKey = (key) => {
        console.log(key);
        if (isCurrentUser && gameState.status === 'running')
            socket.emit('sendKey', key);
    };

    window.addEventListener('resize', () => {
        if (window.innerWidth > window.innerHeight) {
            setContainerStyle({
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'stretch',
                alignItems: 'stretch',
                backgroundColor: '#12171A',
                userSelect: 'none',
                transform: 'rotate(-90deg)',
                transformOrigin: 'left top',
                width: visualViewport.height,
                height: visualViewport.width,
                overflow: 'hidden',
                position: 'absolute',
                top: '100%',
                left: '0',
            });
        } else {
            setContainerStyle({
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'stretch',
                alignItems: 'stretch',
                width: visualViewport.width,
                height: visualViewport.height,
                overflow: 'hidden',
                backgroundColor: '#12171A',
                userSelect: 'none',
            });
        }
    });

    return (
        <div className="remoteContainer" style={containerStyle}>
            <img
                src={RemoteLogoSVG}
                style={{
                    margin: 'auto',
                    width: '100%',
                    maxWidth: '50%',
                }}
            />

            <div
                style={{
                    flex: 0.55,
                    margin: 'auto',
                    width: 'fit-content',
                    maxWidth: '75%',
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 930 930"
                >
                    <g
                        id="Group_14"
                        className="btnRemote"
                        data-name="Group 14"
                        transform="translate(-75 -663)"
                        onClick={() => {
                            sendKey('y');
                        }}
                        onTouchStart=""
                    >
                        <path
                            id="Path_37"
                            data-name="Path 37"
                            d="M673,796A133,133,0,0,0,540,663h0A133,133,0,0,0,407,796V995H673Z"
                            fill="#c6d8e1"
                        >
                            <animate
                                attributeName="fill"
                                values="#c6d8e1;#ffc620;#c6d8e1"
                                dur="0.5s"
                                begin="click"
                            />
                        </path>
                        <circle
                            id="Ellipse_1"
                            data-name="Ellipse 1"
                            cx="48"
                            cy="48"
                            r="48"
                            transform="translate(492 725)"
                            fill="none"
                            stroke="#12171a"
                            stroke-miterlimit="10"
                            stroke-width="10"
                        />
                        <g id="Group_9" data-name="Group 9">
                            <text
                                id="AMARELO-2"
                                data-name="AMARELO"
                                transform="translate(540 973.012)"
                                font-size="100"
                                font-family="Cinderblock-100, Cinderblock"
                                font-weight="100"
                            >
                                <tspan x="-99.365" y="0">
                                    AMARELO
                                </tspan>
                            </text>
                        </g>
                    </g>
                    <g
                        id="Group_16"
                        className="btnRemote"
                        data-name="Group 16"
                        transform="translate(-75 -663)"
                        onClick={() => {
                            sendKey('r');
                        }}
                        onTouchStart=""
                    >
                        <path
                            id="Path_38"
                            data-name="Path 38"
                            d="M407,1460a133,133,0,0,0,133,133h0a133,133,0,0,0,133-133V1261H407Z"
                            fill="#c6d8e1"
                        >
                            <animate
                                attributeName="fill"
                                values="#c6d8e1;#ff0202;#c6d8e1"
                                dur="0.5s"
                                begin="click"
                            />
                        </path>
                        <circle
                            id="Ellipse_2"
                            data-name="Ellipse 2"
                            cx="48"
                            cy="48"
                            r="48"
                            transform="translate(492 1435)"
                            fill="none"
                            stroke="#12171a"
                            stroke-miterlimit="10"
                            stroke-width="10"
                        />
                        <g id="Group_10" data-name="Group 10">
                            <text
                                id="VERMELHO-2"
                                data-name="VERMELHO"
                                transform="translate(540 1353.012)"
                                font-size="100"
                                font-family="Cinderblock-100, Cinderblock"
                                font-weight="100"
                            >
                                <tspan x="-107.764" y="0">
                                    VERMELHO
                                </tspan>
                            </text>
                        </g>
                    </g>
                    <g
                        id="Group_13"
                        className="btnRemote"
                        data-name="Group 13"
                        transform="translate(-75 -663)"
                        onClick={() => {
                            sendKey('g');
                        }}
                        onTouchStart=""
                    >
                        <path
                            id="Path_39"
                            data-name="Path 39"
                            d="M208,995A133,133,0,0,0,75,1128h0a133,133,0,0,0,133,133H407V995Z"
                            fill="#c6d8e1"
                        >
                            <animate
                                attributeName="fill"
                                values="#c6d8e1;#39c5ac;#c6d8e1"
                                dur="0.5s"
                                begin="click"
                            />
                        </path>
                        <circle
                            id="Ellipse_3"
                            data-name="Ellipse 3"
                            cx="48"
                            cy="48"
                            r="48"
                            transform="translate(137 1080)"
                            fill="none"
                            stroke="#12171a"
                            stroke-miterlimit="10"
                            stroke-width="10"
                        />
                        <g id="Group_11" data-name="Group 11">
                            <text
                                id="TURQUESA-2"
                                data-name="TURQUESA"
                                transform="translate(384.809 1128) rotate(-90)"
                                font-size="100"
                                font-family="Cinderblock-100, Cinderblock"
                                font-weight="100"
                            >
                                <tspan x="-102.515" y="0">
                                    TURQUESA
                                </tspan>
                            </text>
                        </g>
                    </g>
                    <g
                        id="Group_15"
                        className="btnRemote"
                        data-name="Group 15"
                        transform="translate(-75 -663)"
                        onClick={() => {
                            sendKey('v');
                        }}
                        onTouchStart=""
                    >
                        <path
                            id="Path_40"
                            data-name="Path 40"
                            d="M872,995H673v266H872a133,133,0,0,0,133-133h0A133,133,0,0,0,872,995Z"
                            fill="#c6d8e1"
                        >
                            <animate
                                attributeName="fill"
                                values="#c6d8e1;#ccb3ff;#c6d8e1"
                                dur="0.5s"
                                begin="click"
                            />
                        </path>
                        <circle
                            id="Ellipse_4"
                            data-name="Ellipse 4"
                            cx="48"
                            cy="48"
                            r="48"
                            transform="translate(847 1080)"
                            fill="none"
                            stroke="#12171a"
                            stroke-miterlimit="10"
                            stroke-width="10"
                        />
                        <g id="Group_12" data-name="Group 12">
                            <text
                                id="LILÁS-2"
                                data-name="LILÁS"
                                transform="translate(695.191 1128) rotate(90)"
                                font-size="100"
                                font-family="Cinderblock-100, Cinderblock"
                                font-weight="100"
                            >
                                <tspan x="-51.514" y="0">
                                    LILÁS
                                </tspan>
                            </text>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    );
}

export default RemoteControl;
