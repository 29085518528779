import React, { useEffect } from 'react';
import Intro from './Intro';
import { socket } from '../service/socket';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Countdown from './Countdown';
import Game from './Game';
import Result from './Result';
import '../assets/css/index.scss';
import PreStart from './PreStart';
import RemoteControl from './RemoteControl';

function App() {
    const idle = () => {
        if (!window.location.pathname.includes('remote'))
            window.location.href = '/screen';
        else window.location.replace('https://www.moche.pt');
    };

    useEffect(() => {
        socket.on('currentUser', (currentUserId) => {
            if (currentUserId === null) {
                idle();
            }
        });

        if (window.location.pathname === '/') {
            idle();
        }

        return () => socket.off();
    }, []);

    return (
        <div className="App">
            <Router>
                <div className="app-container">
                    <Switch>
                        <Route exact path="/screen">
                            <Intro />
                        </Route>
                        <Route exact path="/screen/game">
                            <Game />
                        </Route>
                        <Route exact path="/screen/count">
                            <Countdown />
                        </Route>
                        <Route exact path="/screen/result">
                            <Result />
                        </Route>
                        <Route exact path="/screen/prestart">
                            <PreStart />
                        </Route>
                        <Route path="/remote/:userId">
                            <RemoteControl />
                        </Route>
                    </Switch>
                </div>
            </Router>
        </div>
    );
}

export default App;
