import React, { useState, useEffect } from 'react';
import { socket } from '../service/socket';
import data from '../assets/data/dataGame';
import rightBar from '../assets/img/rightBarCount.svg';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import Result from './Result';
import corectSound from '../assets/sounds/Right.wav';

function Game(props) {
    const [index, setIndex] = useState(0);

    const [gameData] = useState(data);

    const [hidePoints, sethidePoints] = useState(true);

    const [gamePoints, setgamePoints] = useState(0);

    const [clickOnce, setclickOnce] = useState(true);

    const [countDown, setcountDown] = useState(30);

    useEffect(() => {
        socket.on('currentUser', (newUserId) => {
            console.log(newUserId);
        });

        socket.on('getKey', (key) => {
            console.log(key);
            setEventKey(key);
        });

        socket.emit('gameState', {
            status: hidePoints ? 'running' : 'finished',
            time: countDown >= 0 ? countDown : 0,
        });

        return () => {
            socket.off();
        };
    }, [countDown]);

    useEffect(() => {
        const interval = setInterval(() => {
            //randomly choosing item, can be repeated
            const items = [
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
            ];
            const game = items[[Math.floor(Math.random() * items.length)]];
            setIndex((index) => index * 0 + game);

            //to prevent user to click multiple times
            setclickOnce(true);
            //console.log(game);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            sethidePoints(!hidePoints);
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setcountDown((countDown) => countDown - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    function setEventKey(key) {
        //console.log(key);

        let audio = new Audio(corectSound);

        if (gameData[index].answer === key) {
            if (clickOnce) {
                setgamePoints(gamePoints + 1);

                //play the sound if the answer is correct
                audio.play();
            }
            setclickOnce(false);
            console.log(`Points: ${gamePoints}`);
            if (gamePoints > 15) {
                setgamePoints(gamePoints * 0 + 15);
            }
        }
    }
    //console.log(keyPress);
    //console.log(gamePoints);

    const barra = gameData[index].barraMocheText;
    const barraGreen = 'barraMocheGameGreen';
    const barraViolet = 'barraMocheGameViolet';
    const barraYellow = 'barraMocheGameYellow';
    const barraRed = 'barraMocheGameRed';

    if (hidePoints) {
        return (
            <div
                className="Game"
                style={{ backgroundColor: gameData[index].background }}
            >
                <div>
                    <div>
                        <img
                            src={gameData[index].barraMoche}
                            className={`barraMoche ${barra}`}
                            alt="barra moche"
                        />
                    </div>
                    <div>
                        <img
                            src={rightBar}
                            alt="left bar"
                            className="rightBarGame"
                        />
                    </div>
                    <div className="countDown">
                        00:{countDown < 10 ? <span>0</span> : ''}
                        {countDown}
                    </div>
                    <div
                        className="textGame"
                        style={{ color: gameData[index].color }}
                    >
                        {gameData[index].text}
                    </div>
                    <div className="crossGame">
                        <img
                            src={gameData[index].cross}
                            alt="left bar"
                            className="rightBarGame"
                        />
                    </div>
                    <div className="showPoints">
                        {gamePoints}
                        {gamePoints == 1 ? (
                            <span> ponto</span>
                        ) : (
                            <span> pontos</span>
                        )}
                    </div>
                </div>
                <KeyboardEventHandler
                    handleKeys={['y', 'r', 'v', 'g']}
                    onKeyEvent={(key) => setEventKey(key)}
                ></KeyboardEventHandler>
            </div>
        );
    } else {
        return <Result gamePoints={gamePoints} />;
    }
}

export default Game;
