import barraMoche1 from '../img/barraMoche1.svg';
import barraMoche2 from '../img/barraMoche2.svg';
import barraMoche3 from '../img/barraMoche3.svg';
import barraMoche4 from '../img/barraMoche4.svg';
import cross1 from '../img/cross1.svg';
import cross2 from '../img/cross2.svg';
import cross3 from '../img/cross3.svg';
import cross4 from '../img/cross4.svg';

const counter = [
    {
        id: 1,
        number: 3,
        text: 'ready?',
        background: '#ff5959ff',
        color: '#ffdf80ff',
        barraMoche: barraMoche1,
        cross: cross1,
    },
    {
        id: 2,
        number: 2,
        text: 'presta atenção.',
        background: '#ffdf80ff',
        color: '#ff5959ff',
        barraMoche: barraMoche2,
        cross: cross2,
    },
    {
        id: 3,
        number: 1,
        text: 'não te estampes!',
        background: '#7cd9c8ff',
        color: '#ffdf80ff',
        barraMoche: barraMoche3,
        cross: cross3,
    },
    {
        id: 4,
        text: '',
        number: 'go!',
        background: '#ccb3ffff',
        color: '#ff5959ff',
        barraMoche: barraMoche4,
        cross: cross4,
    },
];

export default counter;
