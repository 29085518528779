import React, { useState, useEffect } from 'react';
import data from '../assets/data/dataCounter';
import rightBar from '../assets/img/rightBarCount.svg';
import { AnimateOnChange } from 'react-animation';
import Game from './Game';

function Countdown() {
    const [index, setIndex] = useState(0);
    const [goGame, setGoGame] = useState(false);

    const [countData] = useState(data);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((index) => (index < 3 ? index + 1 : index - 3));
            if (index === 3) {
                setGoGame(true);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [index]);

    /*useEffect(() => {
        const interval = setInterval(() => {
            if (countData[index].number === 'go!') {
                window.location.href = '/game';
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [index]);*/

    if (goGame) {
        return <Game />;
    } else {
        return (
            <div
                className="Countdown"
                style={{ backgroundColor: countData[index].background }}
            >
                <div>
                    {' '}
                    <div>
                        <img
                            src={countData[index].barraMoche}
                            className="barraMoche"
                            alt="barra moche"
                        />
                    </div>
                    <div>
                        <img
                            src={rightBar}
                            alt="left bar"
                            className="rightBarCount"
                        />
                    </div>
                    <div
                        className={`numbersCount ${
                            countData[index].number === 3 ||
                            countData[index].number === 2 ||
                            countData[index].number === 1 ||
                            countData[index].number === 'go!'
                                ? 'textChangeAnim'
                                : ''
                        }`}
                        style={{ color: countData[index].color }}
                    >
                        {countData[index].number}
                    </div>
                    <div className="crossCount">
                        <img
                            src={countData[index].cross}
                            alt="left bar"
                            className="rightBarCount"
                        />
                    </div>
                    <div
                        style={{ color: countData[index].color }}
                        className="textCount"
                    >
                        {countData[index].text}
                    </div>
                </div>
            </div>
        );
    }
}

export default Countdown;
