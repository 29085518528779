import cross5 from '../img/cross5.svg';
import barraMocheGameViolet from '../img/barraMoche1.svg';
import barraMocheGameGreen from '../img/barraMoche2.svg';
import barraMocheGameRed from '../img/barraMoche3.svg';
import barraMocheGameYellow from '../img/barraMoche4.svg';

let red = '#ff5959ff';
let violet = '#ccb3ffff';
let yellow = '#ffdf80ff';
let green = '#7cd9c8ff';

const game = [
    {
        id: 1,
        text: 'amarelo',
        background: red,
        color: green,
        barraMoche: barraMocheGameViolet,
        barraMocheText: 'barraMocheGameViolet',
        cross: cross5,
        answer: 'g',
        pressKey: true,
    },
    {
        id: 2,
        text: 'amarelo',
        background: violet,
        color: yellow,
        barraMoche: barraMocheGameGreen,
        barraMocheText: 'barraMocheGameGreen',
        cross: cross5,
        answer: 'y',
        pressKey: true,
    },
    {
        id: 3,
        text: 'amarelo',
        background: yellow,
        color: violet,
        barraMoche: barraMocheGameRed,
        barraMocheText: 'barraMocheGameRed',
        cross: cross5,
        answer: 'v',
        pressKey: true,
    },
    {
        id: 4,
        number: 3,
        text: 'amarelo',
        background: green,
        color: red,
        barraMoche: barraMocheGameYellow,
        barraMocheText: 'barraMocheGameYellow',
        cross: cross5,
        answer: 'r',
        pressKey: true,
    },
    {
        id: 5,
        text: 'vermelho',
        background: red,
        color: green,
        barraMoche: barraMocheGameViolet,
        barraMocheText: 'barraMocheGameViolet',
        cross: cross5,
        answer: 'g',
        pressKey: true,
    },
    {
        id: 6,
        text: 'vermelho',
        background: violet,
        color: yellow,
        barraMoche: barraMocheGameGreen,
        barraMocheText: 'barraMocheGameGreen',
        cross: cross5,
        answer: 'y',
        pressKey: true,
    },
    {
        id: 7,
        text: 'vermelho',
        background: yellow,
        color: violet,
        barraMoche: barraMocheGameRed,
        barraMocheText: 'barraMocheGameRed',
        cross: cross5,
        answer: 'v',
        pressKey: true,
    },
    {
        id: 8,
        number: 3,
        text: 'vermelho',
        background: green,
        color: red,
        barraMoche: barraMocheGameYellow,
        barraMocheText: 'barraMocheGameYellow',
        cross: cross5,
        answer: 'r',
        pressKey: true,
    },
    {
        id: 9,
        text: 'turquesa',
        background: red,
        color: green,
        barraMoche: barraMocheGameViolet,
        barraMocheText: 'barraMocheGameViolet',
        cross: cross5,
        answer: 'g',
        pressKey: true,
    },
    {
        id: 10,
        text: 'turquesa',
        background: violet,
        color: yellow,
        barraMoche: barraMocheGameGreen,
        barraMocheText: 'barraMocheGameGreen',
        cross: cross5,
        answer: 'y',
        pressKey: true,
    },
    {
        id: 11,
        text: 'turquesa',
        background: yellow,
        color: violet,
        barraMoche: barraMocheGameRed,
        barraMocheText: 'barraMocheGameRed',
        cross: cross5,
        answer: 'v',
        pressKey: true,
    },
    {
        id: 12,
        number: 3,
        text: 'turquesa',
        background: green,
        color: red,
        barraMoche: barraMocheGameYellow,
        barraMocheText: 'barraMocheGameYellow',
        cross: cross5,
        answer: 'r',
        pressKey: true,
    },
    {
        id: 13,
        text: 'lilás',
        background: red,
        color: green,
        barraMoche: barraMocheGameViolet,
        barraMocheText: 'barraMocheGameViolet',
        cross: cross5,
        answer: 'g',
        pressKey: true,
    },
    {
        id: 14,
        text: 'lilás',
        background: violet,
        color: yellow,
        barraMoche: barraMocheGameGreen,
        barraMocheText: 'barraMocheGameGreen',
        cross: cross5,
        answer: 'y',
        pressKey: true,
    },
    {
        id: 15,
        text: 'lilás',
        background: yellow,
        color: violet,
        barraMoche: barraMocheGameRed,
        barraMocheText: 'barraMocheGameRed',
        cross: cross5,
        answer: 'v',
        pressKey: true,
    },
    {
        id: 16,
        number: 3,
        text: 'lilás',
        background: green,
        color: red,
        barraMoche: barraMocheGameYellow,
        barraMocheText: 'barraMocheGameYellow',
        cross: cross5,
        answer: 'r',
        pressKey: true,
    },
];

/*const game = [
    {
        id: 1,
        number: 3,
        text: 'amarelo',
        background: red,
        color: violet,
        barraMoche: barraMocheGameViolet,
        cross: cross5,
        answer: 'v',
        pressKey: true,
    }
];*/
export default game;
