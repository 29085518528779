import React, { useState, useEffect } from 'react';
import { socket } from '../service/socket';

import mainLogo from '../assets/img/mainLogo.svg';
import mochTopLogo from '../assets/img/mocheTopLogo.svg';
//keyboard package
import KeyboardEventHandler from 'react-keyboard-event-handler';
//qrcode package
import QRCode from 'qrcode.react';
//uid package
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';

function Intro() {
    const { frontendUrl } = window['runConfig'];

    const history = useHistory();

    const userId = nanoid(8);

    useEffect(() => {
        const timer = setTimeout(() => {
            history.push('/');
            history.push('/screen');
        }, 30000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        socket.on('currentUser', (currentUserId) => {
            console.log(currentUserId);
            setStart(!start);
        });

        return () => socket.off();
    }, [userId]);

    const [start, setStart] = useState(false);

    if (start) {
        history.push('/screen/prestart');
    }

    function setEventKey(key) {
        if (key === 'y' || 'r' || 'v' || 'g') {
            setStart(!start);
        }
    }
    return (
        <div className="Intro">
            <div className="firstScene">
                <div>
                    <img src={mainLogo} className="mainLogo" alt="main logo" />
                    <p>Não te estampes nessa cor.</p>
                </div>
            </div>
            <div className="secondScene">
                <div>
                    <img
                        src={mochTopLogo}
                        className="mochTopLogo"
                        alt="main top logo"
                    />
                </div>
                <div className="instructions">
                    <div id="firstInstruct">
                        <p>
                            Responde com a <strong>cor da palavra</strong>,{' '}
                            <br /> e não com o que está escrito.
                        </p>
                        <p>
                            Tens <strong>2 segundos</strong> para <br /> cada
                            <strong> Color Block.</strong>
                        </p>
                    </div>
                    <div id="secondInstruct">
                        <p>
                            <strong>
                                Meio minuto para <br /> mostrares o que vales.
                            </strong>
                        </p>
                        <p>
                            Quanto mais acertares, <br /> melhor será o teu
                            <strong> prémio</strong>.
                        </p>
                    </div>
                </div>
            </div>
            <KeyboardEventHandler
                handleKeys={['y', 'r', 'v', 'g']}
                onKeyEvent={(key) => setEventKey(key)}
            />
            <QRCode
                className="qrcode"
                value={`${frontendUrl}/remote/${userId}`}
                bgColor={'transparent'}
                fgColor={'#7cd9c8ff'}
                size={192}
            />
        </div>
    );
}

export default Intro;
